<template>
  <div class="theForm">
    <DuInput name="name" :is-required="true" type="text" label="الاسم" :get-ref="(e) => createFormRef(e)"/>
    <DuInput name="username" :is-required="true" type="text" label="اسم المستخدم" :get-ref="(e) => createFormRef(e)"/>
    <DuInput name="email" :is-required="true" type="email" type-error-message="البريد الإلكتروني غير صحيح" label="البريد الإلكتروني" :get-ref="(e) => createFormRef(e)"/>
    <DuInput name="password" :is-required="true" type="password" label="كلمة المرور"  :get-ref="(e) => createFormRef(e)"/>
    <DuButton text="إنشاء" class="saveButton" font-size="18" @click="save()"/>
  </div>
</template>

<script>
import DuInput from "../../components/DuInput";
import {reactive} from "vue";
import DuButton from "../../components/DuButton";
import AjaxWorker from "../../helpers/AjaxWorker";
import FixedNotification from "../../components/plugins/fixedNotifications/FixedNotification";
import {useRouter} from "vue-router";
export default {
  name: "AddOrEditUser",
  components: {DuButton, DuInput},
  setup() {
    const state = reactive({
      formRefs: {},
    });

    const router = useRouter();

    const createFormRef = (ref) => {
      state.formRefs[ref.state.name] = ref;
    };

    const save = () => {

      let isFormValid = true;
      const _params = {};
      Object.keys(state.formRefs).forEach((key) => {
        const el = state.formRefs[key];
        el.validateNow();
        if(!el.state.isValid) {
          isFormValid = false;
        }
        _params[el.state.name] = el.state.innerValue;
      });

          if(isFormValid) {
            state.isLoading = true;
            new AjaxWorker().request('/transactions.php?action=join', _params).then((res) => {
              console.log(res);
              state.isLoading = false;
              if(res === "NOINTERNETCONNECTION") {
                FixedNotification.create({
                  title: 'حدث خطاء أثناء الإتصال',
                  decription: 'تأكد من إتصالك بالإنترنت',
                  icon: 'wifiF',
                  color: 'red',
                  hideAfter: 3000
                });
              } else {
                if(res.state) {

                  Object.keys(state.formRefs).forEach((key) => {
                    const el = state.formRefs[key];
                    el.state.innerValue = "";
                  });

                    FixedNotification.create({
                      decription: "تم إنشاء الحساب بنجاح",
                      icon: "doneF",
                      color: 'green',
                      hideAfter: 3000
                    });
                  router.replace('/controlPanel/users');

                } else {

                  let errorMessage = 'خطاء غير معلوم حاول مرة أخرى';
                  let errorIcon = 'errorF';
                  if(res.reason === "email_is_already_used") {
                    errorMessage = "هذا البريد مستخدم مسبقًا";
                    errorIcon = "emailF";
                  } else if(res.reason === "phone_is_already_used") {
                    errorMessage = "رقم الهاتف مستخدم مسبقًا";
                    errorIcon = "roundLockF";
                  } else if(res.reason === "username_is_already_used") {
                    errorMessage = "رقم الهاتف مستخدم مسبقًا";
                    errorIcon = "callF";
                  }
                  FixedNotification.create({
                    decription: errorMessage,
                    icon: errorIcon,
                    color: 'red',
                    hideAfter: 3000
                  });

                }

              }
            });

          }
    }
    return {
      state,
      createFormRef,
      save,
    }
  },
}
</script>

<style scoped>
  .theForm{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: available;
    padding: 40px;
  }

  .theForm .saveButton{
    place-self: end;
  }
</style>